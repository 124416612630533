@use "../node_modules/bulma/sass/utilities/mixins";

@use "font.scss";
@use "bulma-overrides.scss";
@use "../node_modules/bulma/sass";
@use "helpers.scss";
@use "solarized-dark.scss";
@use "code-highlight.scss";

body {
  font-family: BlinkMacSystemFont, -apple-system, "Roboto Slab", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
}

@mixin my-box {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  display: block;
  padding: 1.25rem;
}

.blog-post {
  @include mixins.tablet {
    @include my-box;
  }
}
