$h-margin-0: 0px;
$h-margin-1: 1px;
$h-margin-2: 2px;
$h-margin-3: 4px;
$h-margin-4: 8px;
$h-margin-5: 16px;
$h-margin-6: 32px;
$h-margin-7: 64px;

.mt-0 {
  margin-top: $h-margin-0;
}

.mt-1 {
  margin-top: $h-margin-1;
}

.mt-2 {
  margin-top: $h-margin-2;
}

.mt-3 {
  margin-top: $h-margin-3;
}

.mt-4 {
  margin-top: $h-margin-4;
}

.mt-5 {
  margin-top: $h-margin-5;
}

.mt-6 {
  margin-top: $h-margin-6;
}

.mt-7 {
  margin-top: $h-margin-7;
}

.mb-0 {
  margin-bottom: $h-margin-0;
}

.mb-1 {
  margin-bottom: $h-margin-1;
}

.mb-2 {
  margin-bottom: $h-margin-2;
}

.mb-3 {
  margin-bottom: $h-margin-3;
}

.mb-4 {
  margin-bottom: $h-margin-4;
}

.mb-5 {
  margin-bottom: $h-margin-5;
}

.mb-6 {
  margin-bottom: $h-margin-6;
}

.mb-7 {
  margin-bottom: $h-margin-7;
}
