@font-face {
  font-family: "Roboto Slab";
  src: url("/assets/fonts/RobotoSlab-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto Slab";
  src: url("/assets/fonts/RobotoSlab-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto Slab";
  src: url("/assets/fonts/RobotoSlab-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto Slab";
  src: url("/assets/fonts/RobotoSlab-Thin.ttf");
  font-weight: 100;
}
